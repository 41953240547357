// Welcome to Code in Framer
// Get Started: https://www.framer.com/developers/

import React from "react"
import AusstellerPopup from "./AusstellerPopup.tsx"
import { ColumnType } from "./AusstellerTabelle.tsx"

type AusstellerAccordionProps = {
    exhibitor: {
        id: string
        fields: Record<string, string>
    }
    columns: {
        header?: string
        accessorKey?: string
        columnType: ColumnType
    }[]
    expandable: boolean
    cellRenderer: ({
        cell,
    }: {
        cell: { columnType: ColumnType; data: any }
    }) => React.ReactNode
    logoColumn: string
    nameColumn: string
}

/**
 * These annotations control how your component sizes
 * Learn more: https://www.framer.com/developers/#code-components-auto-sizing
 *
 * @framerSupportedLayoutWidth auto
 * @framerSupportedLayoutHeight auto
 */
export default function AusstellerAccordion({
    exhibitor,
    columns,
    expandable,
    cellRenderer,
    logoColumn,
    nameColumn,
    imageColumn,
}: AusstellerAccordionProps) {
    const [open, setOpen] = React.useState(false)

    return (
        <div
            style={{
                borderRadius: "0.5em",
                gridColumn: `span ${columns.length}`,
                padding: "0.5em",
                display: "grid",
                gridTemplateColumns: "subgrid",
            }}
            key={exhibitor.id}
        >
            <div
                onClick={() => {
                    setOpen((value) => {
                        return !value
                    })
                }}
                style={{
                    display: "grid",
                    gridTemplateColumns: "subgrid",
                    gridColumn: `span ${columns.length}`,
                    alignItems: "center",
                }}
            >
                {columns?.map((column, index) => {
                    return (
                        <section
                            key={index}
                            style={{
                                gridColumn: "span 1",
                            }}
                        >
                            {cellRenderer({
                                cell: {
                                    columnType: column.columnType,
                                    data:
                                        exhibitor?.fields[
                                            column.accessorKey.length > 0
                                                ? column.accessorKey
                                                : column.header
                                        ] ?? [],
                                },
                            })}
                        </section>
                    )
                })}
            </div>
            {expandable && open && (
                <AusstellerPopup
                    exhibitor={{
                        fields: exhibitor?.fields,
                        name: exhibitor?.fields[nameColumn],
                        logoSrc: exhibitor?.fields[logoColumn],
                        imageAdSrc: exhibitor?.fields[imageColumn],
                    }}
                />
            )}
        </div>
    )
}
